import {PageHeader} from "antd";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Home() {
    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Accueil"
            />

            Bonjour,
        </>
    )
}

export default Home;

