import './App.css';
import 'animate.css/animate.css';

import {useEffect, useState} from "react";
import {BrowserRouter as Router, Link, Route, Routes, useNavigate} from "react-router-dom";

import {Button, Layout, Tooltip} from "antd";
import {Content, Footer, Header} from "antd/lib/layout/layout";
import {PlusOutlined} from '@ant-design/icons';

import Sider from "antd/es/layout/Sider";
import logo from './logo_b.png';

import Viewer from "./pages/Viewer";
import Home from "./pages/Home";
import Tours from "./pages/_admin/Tours";
import Edit from "./pages/_admin/Edit";
import Login from "./pages/login/Login";
import {AdminMenu} from "./components/AdminMenu";
import {getCurrentUser} from "./api/all";
import {IframePage} from "./pages/iframe";

window.getCookie = function (name) {
    let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function AppLayout(props) {
    return (
        <div>
            <Layout style={{minHeight: '100vh'}}>
                <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={broken => {
                    }}
                    onCollapse={(collapsed, type) => {
                    }}
                >
                    <div className="logo"/>
                    <div className="logo" style={{textAlign: "center", transition: "all 10ms ease"}}>
                        <img alt={'logo'} src={logo}/>
                    </div>
                    <AdminMenu/>
                </Sider>
                <Layout>
                    <Header className="site-layout-sub-header-background">
                        <Link to={"/_admin/edit"}>
                            <Tooltip title="Nouvelle visite">
                                <Button type="dashed" shape="circle" icon={<PlusOutlined/>} size="large"/>
                            </Tooltip>
                        </Link>
                    </Header>
                    <Content style={{margin: '24px 16px 0'}}>
                        <div className="site-layout-background" style={{padding: '0 24px 24px 24px', minHeight: 360}}>
                            {props.children}
                        </div>
                    </Content>
                    <Footer style={{textAlign: 'center'}}>Notoryou</Footer>
                </Layout>
            </Layout>
        </div>
    )
}

function SplashScreen() {
    return (
        <>Chargement ...</>
    )
}

function PrivateRoute({children, ...rest}) {
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getCurrentUser().then(() => {
            setLoading(false);
        }).catch(() => {
            navigate('/_admin/login')
        })
    }, [])

    if (loading) {
        return (<SplashScreen/>);
    }

    return !rest.withoutLayout ? (
        <AppLayout>{children}</AppLayout>
    ) : children;
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => (
    <Router>
        <div className="App">
            <Routes>
                <Route path="/_admin"
                       element={
                           <PrivateRoute>
                               <Home/>
                           </PrivateRoute>
                       }
                />
                <Route path="/_admin/login"
                       element={
                           <Login/>
                       }
                />
                <Route path="/_admin/edit" element={
                    <PrivateRoute>
                        <Edit/>
                    </PrivateRoute>
                }/>
                <Route path="/_admin/edit/:id" element={
                    <PrivateRoute>
                        <Edit/>
                    </PrivateRoute>
                }/>
                <Route path="/_admin/tours" element={
                    <PrivateRoute>
                        <Tours/>
                    </PrivateRoute>
                }/>
                <Route
                    path="/viewer/:id"
                    element={
                        <Viewer/>
                    }
                /><Route
                    path="/iframe"
                    element={
                        <IframePage/>
                    }
                />
            </Routes>
        </div>
    </Router>
);

export default App;

