import React from "react";
import {Col, Image, Layout, Row, Typography} from "antd";
import logo from "../logo@512_light.png";
import "./login/Login.css";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

let LayoutIdentificationPages = (props) => {
    return (
        <Content>
            <Row style={{ paddingTop: 100 }}>
                <Col span={12} offset={6}>
                    <div className="logo-wrapper">
                        <Image src={logo} alt="Logo" width={300} preview={false} style={{
                            'marginBottom': 50,
                            'backgroundColor': "#001529"
                        }} />
                    </div>
                </Col>
                <Col span={12} offset={6}>
                    <Title style={{ textAlign: 'center' }}>{props.title}</Title>
                    { (props.hasOwnProperty('subtitle')) ? (
                        <Paragraph style={{ textAlign: 'center' }}>
                            {props.subtitle}
                        </Paragraph>
                    ) : null }
                </Col>
            </Row>
            <div className="login-form-box">
                {props.children}
            </div>
        </Content>
    );
}

export default LayoutIdentificationPages;
