import {useForm} from "antd/es/form/Form";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button, Card, Col, Form, Input, Modal, notification, Row, Tooltip} from "antd";
import {
    BackwardOutlined,
    CaretRightOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    ForwardOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    LinkOutlined,
    PauseOutlined,
    PlusOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import ReactPlayer from "react-player";
import {Config} from "../config";
import {DrawerVideoUpload} from "./DrawerVideoUpload";
import {videoPublicLink} from "../utils";

/**
 *
 * @param props
 * @param ref
 * @returns {JSX.Element}
 * @constructor
 */
const Iframe = (props, ref) => {
    return (
        <iframe
            className="frame showcase"
            src={props.src}
            ref={ref}
            allow="xr-spatial-tracking"
            allowFullScreen
        ></iframe>
    );
};

/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{src: string}> & React.RefAttributes<unknown>>}
 */
const IFrame2 = forwardRef(Iframe);


/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const Player = forwardRef((props, ref) => {
    const {tour} = props;

    const iframeRef = useRef(null);
    const [mediasLinkFormRef] = useForm();

    const [playing, setPlaying] = useState(false);
    const [mpSdk, setMpSdk] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [steps, setSteps] = useState([]);
    const [ready, setReady] = useState(false);
    const [showVideoGuid, setShowVideoGuid] = useState(true);
    const [showFullscreenGuid, setShowFullscreenGuid] = useState(false);
    const [beforeStepIdx, setBeforeStepIdx] = useState(null);
    const [currentStepIdx, setCurrentStepIdx] = useState(null);
    const [realStepCount, setRealStepCount] = useState(0);
    const [guidScale, setGuidScale] = useState(false);
    const [lastVideoPopupIsEnded, setLastVideoPopupIsEnded] = useState(false);
    const [lastImagePopupIsEnded, setLastImagePopupIsEnded] = useState(false);
    const [direction, setDirection] = useState('next');
    const [videoUploadOpen, setVideoUploadOpen] = useState(false);
    const [inputVideoLink, setInputVideoLink] = useState(null);
    const [inputVideoId, setInputVideoId] = useState(null);

    useEffect(() => {
        if (props.matterportId && props.matterportId !== null) {
            window.MP_SDK.connect(iframeRef.current, Config.matterportKey, '3.2')
                .then((mpSdk) => {

                    mpSdk.on('sweep.exit', () => {
                        setSelectedTag(null);
                    });

                    mpSdk.on('application.phasechange', (v) => {
                        if (v == 'appphase.starting') {
                            init();
                        }
                    });

                    mpSdk.on('camera.move', () => {
                        console.log('camera.move');
                    });

                    mpSdk.on('floors.changestart', () => {
                        console.log('floors.changestart');
                    });

                    mpSdk.on('floors.changeend', () => {
                        console.log('floors.changeend');
                    });

                    mpSdk.on('label.positionupdated', () => {
                        console.log('label.positionupdated');
                    });

                    mpSdk.on('viewmode.changeend', () => {
                        console.log('viewmode.changestart');
                    });

                    mpSdk.on('model.loaded', () => {
                        console.log('model.loaded');
                    });

                    mpSdk.on('sweep.enter', () => {
                        console.log('sweep.enter');
                    });

                    mpSdk.on('sweep.exit', () => {
                        console.log('sweep.exit');
                    });

                    mpSdk.on('tour.ended', () => {
                        console.log('tour.ended');
                    });

                    mpSdk.on('tour.started', () => {
                        console.log('tour.started');
                    });

                    mpSdk.on('tour.stepped', () => {
                        console.log('tour.stepped');
                    });

                    mpSdk.on('tour.stopped', () => {
                        console.log('tour.stopped');
                    });

                    setMpSdk(mpSdk)
                });
        }
    }, [props.matterportId]);

    useEffect(() => {
        if (ready) {
            if (tour.intro_video_url === null) {
                onNext();
            }
        }
    }, [ready]);

    useEffect(() => {
        if ('stepIdToEdit' in props) {
            setCurrentStepIdx(props.stepIdToEdit);
            props.onChangeCurrentStepId(props.stepIdToEdit);
            playStep(props.stepIdToEdit);
        }
    }, [props.stepIdToEdit]);

    useEffect(() => {
        updateSteps(props.steps);
    }, [props.steps]);

    useImperativeHandle(ref, () => ({
        play() {
            onPlay();
        },

        start() {
            onStart();
        },

        reset() {
            init();
        }

    }));

    const init = () => {
        if (props.steps) {
            updateSteps(props.steps);
            if (props.ready) {
                props.ready()
            }
        } else {
            updateSteps([]);
        }
        setCurrentStepIdx(null);
    }

    const updateSteps = (steps) => {

        let count = 0;
        let _steps = [];

        for (const i in steps) {
            let step = steps[i];

            if (i == 0 || (i > 0 && steps[i - 1].videoLink)) {
                count++;
            }

            _steps.push({...step, step_number: count})
        }

        setSteps(_steps);
        setRealStepCount(count);
    }

    const onSave = (stepId) => {
        mpSdk.Camera.getPose()
            .then(async (pose) => {

                let step = typeof stepId !== 'undefined' ? {...steps[stepId]} : {
                    sweep: null,
                    tag: null,
                    videoLink: null,
                    videoId: null
                }

                step.sweep = pose;

                if (typeof stepId !== 'undefined') {
                    step.videoLink = inputVideoLink;
                    step.videoId = inputVideoId;
                    step.stepName = mediasLinkFormRef.getFieldValue('stepName');
                }

                if (step.videoLink == '') {
                    step.videoLink = null;
                }

                if (selectedTag !== null) {
                    step.tag = selectedTag;
                }

                let updatedSteps = [...steps];

                if (typeof stepId !== 'undefined') {
                    updatedSteps[stepId] = step;

                    notification['success']({
                        message: 'Étape modifiée',
                    });
                } else {
                    updatedSteps.push(step);

                    setCurrentStepIdx(updatedSteps.length - 1)
                    props.onChangeCurrentStepId(updatedSteps.length - 1)

                    notification['success']({
                        message: 'Nouvelle étape ajoutée',
                    });
                }

                updateSteps(updatedSteps)

                if (props.onSave) {

                    // permet de gérer la migration matterport des id sweep de la v1 vers la v2 pour les id déjà stocké
                    // pourra être supprimé après avoir basculé toutes les visites sur la v2
                    const mapping = await mpSdk.Sweep.Conversion.createIdMap(true);
                    for(let step of steps) {
                        let sweepId = mapping[step.sweep.sweep] ? mapping[step.sweep.sweep] : step.sweep.sweep;
                        step.sweep.sweep = sweepId;
                    }

                    props.onSave(updatedSteps, step);
                }
            });
    }

    /**
     *
     */
    const onStart = () => {
        if (tour.intro_video_url === null) {
            setLastImagePopupIsEnded(true)
        }

        setCurrentStepIdx(-1);
    }

    /**
     *
     */
    const onPlay = () => {
        setPlaying(true)

        if (currentStep && currentStep !== null && currentStep.videoLink === null) {
            if (direction == 'next') {
                onNext();
            } else {
                onBack();
            }
        }
    }

    /**
     *
     */
    const onPause = () => {
        setPlaying(false)
    }

    /**
     *
     */
    const onLink = (e) => {
        if (tour === null) {
            e.preventDefault();
            Modal.info({
                title: "La visite n'est pas enregistrés",
                content: (
                    <div>
                        <p>Veuillez enregistrer le tour pour visualiser le lien</p>
                    </div>
                ),
                onOk() {
                },
            });
        }
    }

    /**
     *
     * @returns {number}
     */
    const onNext = async () => {

        setDirection('next');

        let newCurrentStepIdx = currentStepIdx;

        if (currentStepIdx + 1 >= steps.length) {
            onPause();

            if (props.onEnd) {
                props.onEnd();
            }
        } else {
            newCurrentStepIdx++;
            await updateStep(newCurrentStepIdx);
        }

        setLastImagePopupIsEnded(false);
        setLastVideoPopupIsEnded(false);

        return newCurrentStepIdx;

    }

    const updateStep = async (stepIdx) => {
        setBeforeStepIdx(stepIdx);
        setPlaying(true);

        await playStep(stepIdx);
        setCurrentStepIdx(stepIdx);

        if (props.onStepChange) {
            props.onStepChange(stepIdx);
        }
    }

    /**
     *
     */
    const onBack = async () => {
        setDirection('back');

        let newCurrentStepIdx = currentStepIdx;

        if (currentStepIdx - 1 < 0) {
            newCurrentStepIdx = 0;
        } else {
            newCurrentStepIdx--;
        }

        await updateStep(newCurrentStepIdx);

        return newCurrentStepIdx;

    }

    const currentStepVideoOnEnded = () => {
        if (tour.auto_play) {
            onNext();
        } else {
            setLastVideoPopupIsEnded(true)
        }
    }

    const onClickToggleVideoGuid = () => {
        setShowVideoGuid(!showVideoGuid);
    }

    const onClickFullScreenGuid = () => {
        setShowFullscreenGuid(!showFullscreenGuid);
    }

    /**
     *
     * @param stepId
     */
    const playStep = async (stepId) => {
        if (stepId + 1 > steps.length) {
            return;
        }

        let step = steps[stepId];

        if (step.tag && step.tag !== null) {
            mpSdk.Mattertag.navigateToTag(step.tag, mpSdk.Mattertag.Transition.FLY)
        } else {
            step.sweep.transition = mpSdk.Sweep.Transition.FLY;
            step.sweep.transitionTime = props.mode == 'edit' ? 1 : 2000;

            // Gestion du passage entre la v1 et la v2 des id de sweep enregistrée. Migration effectuée par Matterport
            const mapping = await mpSdk.Sweep.Conversion.createIdMap(true);
            let sweepId = mapping[step.sweep.sweep] ? mapping[step.sweep.sweep] : step.sweep.sweep;

            await mpSdk.Sweep.moveTo(sweepId, step.sweep);
        }

        setLastVideoPopupIsEnded(false);
        setLastImagePopupIsEnded(false);

        setInputVideoId(step.videoId);
        setInputVideoLink(step.videoLink);
        mediasLinkFormRef.setFieldsValue({
            'stepName': step.stepName,
        });
    }

    /**
     * Ensemble necessaire pour avoir une bonne gestion du timeout et de l'accès aux données du states dans le timetout
     */
    useEffect(() => {
        let timeout = null;

        if (currentStepIdx == null) {
            playStep(0);
        } else if (currentStepIdx > -1) {
            let step = steps[currentStepIdx];

            if (step && (!step.videoLink || step.videoLink === null || step.videoLink === '') && props.mode !== 'edit' && playing) {
                if (direction == 'next') {
                    onNext();
                } else {
                    onBack();
                }
            }
        } else if (currentStepIdx == -1 && tour.auto_play && props.mode !== 'edit') {
            onNext();
        }

        return () => clearTimeout(timeout);
    }, [currentStepIdx]);

    // useLegacyIds necessaire pour continuer d'utiliser les ID de sweep déjà enregistrer. Migration manuelle à prévoir
    // let url = "https://my.matterport.com/show/?m=" + props.matterportId + "&help=0&play=1&qs=1&gt=0&hr=0&useLegacyIds=1"
    let url = "https://my.matterport.com/show/?m=" + props.matterportId + "&help=0&play=1&qs=1&gt=0&hr=0"

    let nextBtnClasses = ['next-btn'];
    if (lastVideoPopupIsEnded || lastImagePopupIsEnded) {
        nextBtnClasses = [...nextBtnClasses, ...['next-animation']];
    }

    let adminActions = [{
        'component': (
            <>
                <Tooltip title="Ajouter une nouvelle étape">
                    <Button type="success" onClick={() => {
                        setInputVideoId(null);
                        setInputVideoLink(null);
                        mediasLinkFormRef.setFieldValue('stepName', null);
                        onSave()
                    }}
                            shape="circle" icon={<PlusOutlined/>} size="large"></Button>
                </Tooltip>
            </>
        )
    }, {
        'component': (
            <Tooltip title="Étape précédente">
                <Button type="success" onClick={onBack.bind(this)}
                        shape="circle" icon={<BackwardOutlined/>} size="large"
                        disabled={currentStepIdx == 0}></Button>
            </Tooltip>
        )
    }/*, {
        'component': (
            <Tooltip title="Jouer la visite">
                <Button type="success" onClick={onPlay.bind(this)}
                        shape="circle" icon={<CaretRightOutlined/>} size="large"></Button>
            </Tooltip>
        )
    }, {
        'component': (
            <Tooltip title="Stoper la visite">
                <Button type="success" onClick={onPause.bind(this)}
                        shape="circle" icon={<PauseOutlined/>} size="large"></Button>
            </Tooltip>
        )
    }*/, {
        'component': (
            <div>{currentStepIdx + 1} / {steps.length}</div>
        )
    }, {
        'component': (
            <Tooltip title="Étape suivante">
                <Button type="success" onClick={onNext.bind(this)} className={nextBtnClasses}
                        shape="circle" icon={<ForwardOutlined/>} size="large"></Button>
            </Tooltip>
        )
    }, {
        'component': tour !== null ? (
            <Tooltip title="Copier le lien publique">
                <Link to={'/viewer/' + tour.uuid} target={'_blank'} onClick={onLink}>
                    <Button type="success"
                            shape="circle" icon={<LinkOutlined/>} size="large"></Button>
                </Link>
            </Tooltip>
        ) : (
            <Tooltip title="Copier le lien publique">
                <Button disabled={true} type="success"
                        shape="circle" icon={<LinkOutlined/>} size="large"></Button>
            </Tooltip>
        )
    }]

    let disableActions = false;
    let actionClasses = ['action'];

    if (playing && beforeStepIdx !== currentStepIdx) {
        disableActions = true;
    }

    if (disableActions) {
        actionClasses.push('action-opacity');
    }

    let playerActions = [{
        'component': (
            <div className={actionClasses.join(' ')} onClick={() => {
                if (currentStepIdx > 0) {
                    onBack().catch(console.log);
                }
            }}>
                <BackwardOutlined/>
            </div>
        )
    }, {
        'component': (
            <>
                {
                    playing ? <div className={'action'} onClick={() => {
                        onPause().catch(console.log);

                    }}>
                        <PauseOutlined/>
                    </div> : <div className={actionClasses.join(' ')} onClick={() => {
                        if (!disableActions) {
                            onPlay().catch(console.log);
                        }
                    }}>
                        <CaretRightOutlined/>
                    </div>
                }
            </>
        ),
        'modes': []
    }, {
        'component': (
            <div className={actionClasses.join(' ')} onClick={() => {
                if (!disableActions && currentStepIdx < steps.length) {
                    onNext().catch(console.log);
                }
            }}>
                <ForwardOutlined/>
            </div>
        )
    }];

    let selectedActions = props.mode === 'demo' ? playerActions : adminActions;

    let stepVideoLink = null;
    let currentStep = null;
    if (steps.length > 0) {
        currentStep = steps[currentStepIdx];
    }

    if (currentStep && currentStep !== null) {
        stepVideoLink = currentStep.videoLink;
    }

    let stepContentEditor = null;
    if (props.mode == 'edit') {
        stepContentEditor = currentStepIdx !== null ? (
            <Row>
                <Col style={{padding: "15px 0"}} span={24}>
                    <Card
                        title={`Édition de l'étape n°${currentStepIdx + 1}`}
                        bordered={false}>
                        <Form
                            layout={"vertical"}
                            form={mediasLinkFormRef}
                        >
                            <Form.Item
                                name="stepName"
                            >
                                <Input placeholder={"Nom de l'étape"}></Input>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    icon={<VideoCameraOutlined/>}
                                    style={{
                                        width: "100%"
                                    }} onClick={() => {
                                    setVideoUploadOpen(!videoUploadOpen);
                                }}>
                                    {inputVideoId ? 'Éditer la vidéo' : 'Ajouter une vidéo'}
                                </Button>
                            </Form.Item>
                            <Row>
                                <Col span={24}>
                                    <Button type={"primary"} style={{
                                        width: "100%"
                                    }} onClick={() => {
                                        onSave(currentStepIdx);
                                    }}>Enregistrer</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        ) : '';
    }

    let stepGuidContent = null;
    let stepGuidContentBox = null;
    let showVideoGuidBtn = null;
    let guidContentClasses = [];
    if (props.mode == 'demo') {

        if (currentStep && (currentStep.videoLink)) {
            let guidContentBoxClasses = ['guid_content_box'];
            let showVideoGuidIco = (<EyeOutlined/>);
            let fullscreenIco = (<FullscreenOutlined/>);
            let fullscreenBtn = null;

            if (showFullscreenGuid) {
                // guidContentClasses.push('fullscreen animate__animated animate__fadeIn');
                fullscreenIco = (<FullscreenExitOutlined/>);
            }

            if (showVideoGuid && !currentStep.videoLink) {
                fullscreenBtn = (<Button shape="circle" icon={fullscreenIco} className={'fullscreen_guid'}
                                         onClick={onClickFullScreenGuid}/>)
            }

            if (showVideoGuid) {
                guidContentClasses.push('animate__animated animate__bounceIn')
            } else {
                guidContentClasses.push('animate_hide')
                showVideoGuidIco = (<EyeInvisibleOutlined/>)
            }

            if (!showFullscreenGuid) {
                showVideoGuidBtn = (
                    <div className={'hide_video_guid'} onClick={onClickToggleVideoGuid}>{showVideoGuidIco}</div>);
            }

            let contentMedia = null;
            if (currentStep.videoLink) {
                contentMedia = (
                    <ReactPlayer url={stepVideoLink} playing={playing} pip={false} width={'100%'} height={'100%'}
                                 onEnded={currentStepVideoOnEnded}
                                 controls={true} playsinline={true} config={{
                        youtube: {
                            playerVars: {modestbranding: 0}
                        },
                        vimeo: {
                            playsinline: true
                        }
                    }}/>
                )
            }

            stepGuidContentBox = (
                <div className={guidContentClasses.join(' ')}>
                    <div>
                        <div className={guidContentBoxClasses.join(' ')}>
                            {contentMedia}
                        </div>
                        {fullscreenBtn}
                    </div>
                </div>
            )
        }

        let guidClassName = ['guid'];
        if (guidScale) {
            guidClassName.push('scale')
        }

        let stepTitleParts = [];

        if (steps.length > 0 && currentStepIdx >= 0 && currentStepIdx !== null && steps[beforeStepIdx]) {
            stepTitleParts.push(`${steps[beforeStepIdx].step_number} / ${realStepCount}`);
        } else {
            stepTitleParts.push(`1 / ${realStepCount}`)
        }

        if (steps.length > 0 && currentStepIdx >= 0 && currentStepIdx !== null && steps[beforeStepIdx] && steps[beforeStepIdx].stepName) {
            stepTitleParts.push(steps[beforeStepIdx].stepName);
        }

        stepGuidContent = (
            <div className={'guid_content'}>
                {showVideoGuidBtn}
                <div className={'guid_step_title'}>
                    {stepTitleParts.join(' - ')}
                </div>
                {stepGuidContentBox}
                <div className={'actions_bar'}>
                    {selectedActions.map(action => action.component)}
                </div>
            </div>
        )
    }

    let showcaseContent = 'Aucun id Matterport';
    if (props.matterportId !== null) {
        showcaseContent = (
            <IFrame2 ref={iframeRef} src={url}></IFrame2>
        )
    }

    return (
        <>
            <div className={'showcase_container_row'}>
                <div className="showcase_container">
                    {showcaseContent}
                </div>
            </div>
            <div className={'showcase_controls_row'}>
                {
                    props.mode != 'demo' && (
                        <div style={{
                            padding: "10px 0",
                            display: 'flex',
                            gap: "5px",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            {selectedActions.map(action => action.component)}
                        </div>
                    )
                }
                {stepContentEditor}
            </div>
            {stepGuidContent}
            <DrawerVideoUpload videoId={inputVideoId} open={videoUploadOpen} onClose={() => {
                setVideoUploadOpen(false);
            }} onSelect={(video) => {
                setVideoUploadOpen(false);
                setInputVideoLink(videoPublicLink(video));
                setInputVideoId(video.id);
            }} onDelete={() => {
                setVideoUploadOpen(false);
                setInputVideoId(null);
                setInputVideoLink(null);
            }}/>
        </>
    )
});

export default Player;

