import {Menu} from "antd";
import {HomeOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

export const AdminMenu = () => {
    return (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
            <Menu.Item key="1" icon={<HomeOutlined/>}>
                <Link to={"/_admin"}>Accueil</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<VideoCameraOutlined/>}>
                <Link to={"/_admin/tours"}>Visites guidées</Link>
            </Menu.Item>
        </Menu>
    )
}
