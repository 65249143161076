import 'react-quill/dist/quill.snow.css';

import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Config} from "../../config";
import {Button, Card, Col, Divider, Input, notification, PageHeader, Row, Space, Switch,} from "antd";

import Player from "../../components/Player";
import ReactQuill from "react-quill";
import {CSRFFetch} from "../../api/CSRFFetch";
import {getTour} from "../../api/all";
import {StepsTimeline} from "../../components/StepsTimeline";
import {VideoCameraOutlined} from "@ant-design/icons";
import {DrawerVideoUpload} from "../../components/DrawerVideoUpload";
import {videoPublicLink} from "../../utils";


/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Edit(props) {

    let {id} = useParams();
    let navigate = useNavigate();

    const [steps, setSteps] = useState([])
    const [tour, setTour] = useState(null)
    const [name, setName] = useState(null)
    const [matterportId, setMatterportId] = useState(null)
    const [introVideoUrl, setIntroVideoUrl] = useState(null);
    const [conclusionVideoUrl, setConclusionVideoUrl] = useState(null);
    const [autoPlay, setAutoPlay] = useState(false);
    const [stepIdToEdit, setStepIdToEdit] = useState(null);
    const [currentStepIdx, setCurrentStepIdx] = useState(null);
    const [introductionMessage, setIntroductionMessage] = useState(null);
    const [conclusionMessage, setConclusionMessage] = useState(null);
    const [showBranding, setShowBranding] = useState(true);
    const [videoUploadOpen, setVideoUploadOpen] = useState(false);
    const [inputVideoId, setInputVideoId] = useState(null);
    const [inputVideoPhaseName, setInputVideoPhaseName] = useState(null);

    const [tourVideos, setTourVideos] = useState({
        intro_url: null,
        intro_id: null,
        conclusion_id: null,
        conclusion_url: null
    })

    const quillModules = {
        toolbar: [
            [{'header': [1, 2, false]}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean'],
            [{'color': []}, {'background': []}]
        ],
    };

    useEffect(() => {

        setSteps([]);
        setTour(null);
        setName(null);
        setMatterportId(null);

        getTour(id).then(data => {
            let tour = data.tour;
            setSteps(JSON.parse(tour.steps));
            setTour(tour);
            setName(tour.name);
            setMatterportId(tour.matterport_id);
            // setIntroVideoUrl(tour.intro_video_url);
            setTourVideos({
                intro_url: tour.intro_video_url,
                intro_id: tour.intro_video_id,
                conclusion_id: tour.conclusion_video_id,
                conclusion_url: tour.conclusion_video_url,
            });
            // setConclusionVideoUrl(tour.conclusion_video_url);
            setAutoPlay(tour.auto_play || false);
            setIntroductionMessage(tour.intro_message);
            setConclusionMessage(tour.conclusion_message);
            setShowBranding(tour.show_branding);
        })
    }, [id]);

    const onSave = (steps) => {
        setSteps(steps)
    }

    const onPersist = (_tourVideos) => {

        let videos = _tourVideos || tourVideos;

        let method = (id && id !== null) ? 'PUT' : 'POST';
        let pathUrl = (id && id !== null) ? '/' + id : '';

        CSRFFetch(Config.apiUrl + '/api/tour' + pathUrl, method, {
            'name': name,
            'matterport_id': matterportId,
            'auto_play': autoPlay,
            'intro_video_url': videos.intro_url,
            'intro_video_id': videos.intro_id,
            'conclusion_video_url': videos.conclusion_url,
            'conclusion_video_id': videos.conclusion_id,
            'intro_message': introductionMessage,
            'conclusion_message': conclusionMessage,
            'show_branding': showBranding,
            'steps': JSON.stringify(steps)
        }).then((data) => {
            navigate("/_admin/edit/" + data.tour.uuid);

            notification['success']({
                message: 'Votre visite est enregistrée',
            });
        })
    }

    /**
     *
     * @param e
     */
    const onChangeInput = (e) => {
        setName(e.target.value);
    }

    /**
     *
     * @param e
     */
    const onChangeMatterportInput = (e) => {
        setMatterportId(e.target.value);
    }

    /**
     *
     * @param e
     */
    const onChangeIntroVideoUrl = (e) => {
        setIntroVideoUrl(e.target.value);
    }

    /**
     *
     * @param e
     */
    const onChangeConclusionVideoUrl = (e) => {
        setConclusionVideoUrl(e.target.value);
    }

    /**
     *
     * @param e
     */
    const onChangeAutoPlay = (checked) => {
        setAutoPlay(checked);
    }

    /**
     *
     * @param e
     */
    const onChangeBranding = (checked) => {
        setShowBranding(checked);
    }

    /**
     *
     * @param data
     * @param from
     * @param to
     */
    const moveItem = (arr, from, to) => {
        arr = [...arr]
        let a = arr[from]
        let b = arr[to]
        arr[from] = b
        arr[to] = a
        return arr;
    }

    /**
     *
     * @param stepId
     */
    const removeStep = (stepId) => {
        let newSteps = [...steps]
        newSteps.splice(stepId, 1);
        setSteps(newSteps);
        if (newSteps.length > 1) {
            setStepIdToEdit(newSteps.length - 1);
        }
    }

    /**
     *
     * @param stepId
     */
    const upStep = (stepId) => {
        if (stepId <= 0) {
            return;
        }
        setSteps(moveItem(steps, stepId - 1, stepId));
    }

    /**
     *
     * @param stepId
     */
    const downStep = (stepId) => {

        if (stepId + 1 >= steps.length) {
            return;
        }

        setSteps(moveItem(steps, stepId + 1, stepId));
    }

    /**
     *
     * @param stepId
     */
    const editStep = (stepId) => {
        setStepIdToEdit(stepId);
    }

    /**
     *
     * @param stepId
     */
    const onChangeCurrentStepId = (stepId) => {
        setCurrentStepIdx(stepId);
        setStepIdToEdit(stepId);
    }

    /**
     *
     * @param text
     */
    const onChangeConclusionMessage = (text) => {
        setConclusionMessage(text)
    }

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Création d'une visites"
            />
            <Row gutter={16}>
                <Col span={12}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Input placeholder={'Nom'} onChange={onChangeInput} value={name}/>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Input style={{margin: '10px 0px 0px 0px'}} placeholder={'Matterport ID'}
                                   onChange={onChangeMatterportInput} value={matterportId}/>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Space style={{margin: '10px 0px 0px 0px'}}>
                                <Switch onChange={onChangeAutoPlay} checked={autoPlay}/> Lecture
                                automatique
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Space style={{margin: '10px 0px 0px 0px'}}>
                                <Switch onChange={onChangeBranding} checked={showBranding}/>
                                Afficher le logo "Guide3dtour"
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Card title="Vue 3D" bordered={false}>
                        <div className="container showcase_preview">
                            <Player steps={steps} stepIdToEdit={stepIdToEdit} tour={tour} onSave={onSave}
                                    onStepChange={(id) => {
                                        setCurrentStepIdx(id)
                                    }}
                                    onChangeCurrentStepId={onChangeCurrentStepId} matterportId={matterportId}
                                    mode={'edit'}/>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <StepsTimeline currentStepIdx={currentStepIdx} steps={steps} editStep={editStep} upStep={upStep}
                                   downStep={downStep} removeStep={removeStep}/>
                </Col>
                <Col span={12}>
                    <Card title="Media d'introduction (optionnel)" bordered={false}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Button
                                    icon={<VideoCameraOutlined/>}
                                    style={{
                                        width: "100%"
                                    }} onClick={() => {
                                    setVideoUploadOpen(!videoUploadOpen);
                                    setInputVideoId(tourVideos.intro_id);
                                    setInputVideoPhaseName('intro');
                                }}>
                                    {tourVideos.intro_id ? 'Éditer la vidéo' : 'Ajouter une vidéo'}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                    <Card title="Media de conclusion (optionnel)" bordered={false}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Button
                                    icon={<VideoCameraOutlined/>}
                                    style={{
                                        width: "100%"
                                    }} onClick={() => {
                                    setVideoUploadOpen(!videoUploadOpen);
                                    setInputVideoId(tourVideos.conclusion_id);
                                    setInputVideoPhaseName('conclusion');
                                }}>
                                    {tourVideos.conclusion_id ? 'Éditer la vidéo' : 'Ajouter une vidéo'}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                    <Card title="Message de conclusion" bordered={false}>
                        <ReactQuill modules={quillModules}
                                    theme="snow" value={conclusionMessage} onChange={onChangeConclusionMessage}/>
                    </Card>
                </Col>
            </Row>
            <Divider/>
            <Row>
                <Col span={24} style={{textAlign: 'right'}}>
                    <Space><Button type="primary" onClick={() => {
                        onPersist()
                    }}>Enregistrer</Button></Space>
                </Col>
            </Row>
            <DrawerVideoUpload videoId={inputVideoId} open={videoUploadOpen} onClose={() => {
                setVideoUploadOpen(false);
            }} onSelect={(video) => {
                setVideoUploadOpen(false);
                setInputVideoId(null);

                let _tourVideos = {...tourVideos}

                if (inputVideoPhaseName == 'intro') {
                    _tourVideos.intro_id = video.id;
                    _tourVideos.intro_url = videoPublicLink(video);
                } else if (inputVideoPhaseName == 'conclusion') {
                    _tourVideos.conclusion_id = video.id;
                    _tourVideos.conclusion_url = videoPublicLink(video);
                }

                setTourVideos(_tourVideos);
            }} onDelete={() => {
                setVideoUploadOpen(false);
                setInputVideoId(null);

                let _tourVideos = {...tourVideos}

                if (inputVideoPhaseName == 'intro') {
                    _tourVideos.intro_id = null;
                    _tourVideos.intro_url = null;
                } else if (inputVideoPhaseName == 'conclusion') {
                    _tourVideos.conclusion_id = null;
                    _tourVideos.conclusion_url = null;
                }

                setTourVideos(_tourVideos);
                onPersist(_tourVideos);
            }}/>
        </>
    );
}

export default Edit;

