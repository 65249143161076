import React, {useEffect} from "react";
import {Button, Form, Input, Layout, notification} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {login, logout} from "../../api/all";

const {Content} = Layout;

const NormalLoginForm = () => {
    const [formLogin] = Form.useForm();
    let navigate = useNavigate();

    useEffect(() => {
        logout().catch(() => {
        });
    })

    const onFinish = (values) => {
        login(values.username, values.password).then(() => {
            navigate('/_admin/tours');
        }).catch(() => {
            notification['error']({
                message: "Erreur d'authentification",
            });
        })
    };

    return (
        <Content>
            <Form
                form={formLogin}
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: "Le nom d'utilisateur ne peut pas être vide",
                        },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon"/>}
                        placeholder="Nom d'utilisateur"
                    />
                </Form.Item>

                <Form.Item
                    className="mb-2"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Le password ne peut pas être vide",
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="Mot de passe"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Connexion
                    </Button>
                </Form.Item>
            </Form>
        </Content>
    );
};

export default NormalLoginForm;
