import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import {Button, Card, Col, Popconfirm, Row, Space, Timeline} from "antd";
import {useEffect} from "react";

export const StepsTimeline = ({currentStepIdx, steps, editStep, upStep, downStep, removeStep}) => {

    if (!Array.isArray(steps)) {
        return (<></>);
    }

    let stepsTimeline = steps.map((step, i) => {

        let dot = null;

        if (i === currentStepIdx) {
            dot = (<EyeOutlined/>)
        }

        let mediaLink = null;
        if (step.videoLink) {
            mediaLink = (
                <div><a target={"_blank"} href={step.videoLink}><VideoCameraOutlined/></a></div>
            )
        }

        return (
            <Timeline.Item dot={dot}>
                <Row>
                    <Col flex="auto">
                        <div>étape n°{i + 1} : {step.stepName}</div>
                        {mediaLink}
                    </Col>
                    <Col flex="50px">
                        <Space size={'small'}>
                            <Button shape="circle" icon={<EditOutlined/>} size={'small'} onClick={() => {
                                editStep(i)
                            }}/>
                            <Button shape="circle" icon={<ArrowUpOutlined/>} size={'small'} onClick={() => {
                                upStep(i)
                            }}/>
                            <Button shape="circle" icon={<ArrowDownOutlined/>} size={'small'} onClick={() => {
                                downStep(i)
                            }}/>
                            <Popconfirm placement="top" title={"Confirmez-vous la suppression ?"} onConfirm={() => {
                                removeStep(i)
                            }} okText="Yes" cancelText="No">
                                <Button shape="circle" icon={<DeleteOutlined/>} size={'small'}/>
                            </Popconfirm>
                        </Space>
                    </Col>
                </Row>
            </Timeline.Item>)
    })

    return (
        <Card title="Étapes de la visite" bordered={false}>
            <Timeline>
                {stepsTimeline}
            </Timeline>
        </Card>
    )
}