import {CSRFFetch} from "./CSRFFetch";
import {Config} from "../config";

export const getCurrentUser = () => {
    return CSRFFetch(Config.apiUrl + '/api/user', 'GET');
}

export const login = (username, password) => {
    return CSRFFetch(Config.apiUrl + '/login', 'POST', {
        'email': username,
        'password': password
    })
}


export const logout = () => {
    return CSRFFetch(Config.apiUrl + '/logout', 'POST')
}


export const getTour = (id) => {
    return CSRFFetch(Config.apiUrl + '/api/tour/' + id, 'GET');
}

export const getTours = () => {
    return fetch(Config.apiUrl + '/api/tours', {
        credentials: 'include'
    }).then(resp => {
        return resp.json();
    })
}

export const deleteTour = (id) => {
    return CSRFFetch(Config.apiUrl + '/api/tour/' + id, 'delete');
}
