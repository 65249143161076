import {Button, Drawer, Form, Input, message, Popconfirm, Space, Upload} from "antd";
import {useEffect, useState} from "react";
import {CopyOutlined, DeleteOutlined, InboxOutlined, SaveOutlined} from '@ant-design/icons';
import {Config} from "../config";
import {CSRFFetch} from "../api/CSRFFetch";
import Cookies from "js-cookie";
import ReactPlayer from "react-player";
import {videoPublicLink} from "../utils";

const {Dragger} = Upload;

export const DrawerVideoUpload = ({open, onClose, onSelect, onDelete, videoId}) => {

    const [csrf, setCsrf] = useState(null);
    const [form] = Form.useForm();
    const [lastFilenameUploaded, setLastFilenameUploaded] = useState(null);
    const [videoToEdit, setVideoToEdit] = useState(null);

    useEffect(() => {
        if (!open) {
            reset();
        } else {
            fetch(Config.apiUrl + '/sanctum/csrf-cookie', {
                method: 'GET',
                'credentials': 'include',
            }).then(() => {
                setCsrf(Cookies.get('XSRF-TOKEN'))
            })

            if (videoId) {
                CSRFFetch(Config.apiUrl + '/api/video/' + videoId, 'GET').then((data) => {
                    setVideoToEdit(data.video)
                })
            }
        }
    }, [open]);

    const reset = () => {
        setCsrf(null);
        setVideoToEdit(null);
        setLastFilenameUploaded(null);
        form.resetFields();
    }

    const props = {
        accept: "video/mp4",
        name: 'file',
        multiple: false,
        maxCount: 1,
        action: Config.apiUrl + '/api/video/file',
        withCredentials: true,
        headers: {
            'X-XSRF-TOKEN': csrf
        },
        beforeUpload: (file) => {
            let maxSize = 500000000
            if (file.size > maxSize) {
                message.error(`La taille du fichier dépasse la taille maximale autorisée`);
                return false;
            }
        },
        onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') { }
            if (status === 'done') {
                setLastFilenameUploaded(info.file.response.filename);
                message.success(`"${info.file.name}" envoyé avec succès.`);
            } else if (status === 'error') {
                message.error(`Impossible d'envoyer le fichier "${info.file.name}".`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    /**
     *
     */
    const saveHandler = () => {
        if (videoId) {
            updateVideo();
        } else {
            createVideo();
        }
    }

    const deleteHandler = () => {
        if (videoId) {
            deleteVideo();
        }
    }

    const updateVideo = () => {
        let filename = lastFilenameUploaded || videoToEdit.filename

        if (!filename) {
            message.error(`Fichier absent`);
            return;
        }

        CSRFFetch(Config.apiUrl + '/api/video/' + videoId, 'PUT', {
            'filename': filename
        }).then((data) => {
            onSelect(data.video);
            message.success(`Vidéo enregistrée avec succès.`);
        })
    }

    const createVideo = () => {

        if (!lastFilenameUploaded) {
            message.error(`Fichier absent`);
            return;
        }

        CSRFFetch(Config.apiUrl + '/api/video', 'POST', {
            'filename': lastFilenameUploaded,
        }).then((data) => {
            onSelect(data.video);
            message.success(`Vidéo enregistrée avec succès.`);
        })
    }

    const deleteVideo = () => {
        CSRFFetch(Config.apiUrl + '/api/video/' + videoId, 'DELETE').then((data) => {
            onDelete();
            message.success(`"Vidéo supprimée avec succès.`);
        })
    }

    return (
        <Drawer title={videoId ? "Édition d'un vidéo" : "Nouvelle vidéo"} placement="right" onClose={onClose}
                open={open} size={'large'} extra={
            <Space>
                <Popconfirm placement="bottomLeft" title={"Êtes vous sur de vouloir supprimer cette vidéo ?"}
                            onConfirm={() => {
                                deleteHandler()
                            }} okText="Oui" cancelText="Non">
                    <Button type={'text'} icon={<DeleteOutlined/>}></Button>
                </Popconfirm>
                <Button type={'primary'} icon={<SaveOutlined/>} onClick={saveHandler}>Enregistrer</Button>
            </Space>
        }>
            <div style={{
                display: "grid",
                gridTemplateRows: "200px 400px",
                height: "100%",
                gap: "8px"
            }}>
                <div>

                    <Dragger key={open} {...props}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Cliquer ou déplacer un fichier à cet endroit</p>
                        <p className="ant-upload-hint">
                            Fichier .mp4 uniquement et moins de 500 Mo.
                        </p>
                    </Dragger>
                </div>
                <div>
                    {videoToEdit &&
                        <ReactPlayer style={{
                            marginTop: "25px"
                        }} url={videoPublicLink(videoToEdit)} width={"100%"} height={"100%"}
                                     controls={true}/>}
                </div>
            </div>
        </Drawer>
    )
}