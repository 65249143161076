import {Config} from "./config";

/**
 * Fonction issue d'exemple Mattermort pour assurer le déplacement entre 2 sweep en suivant un chemin le plus court (pathfinding). Cela évite le passage à travers les murs.
 *
 * La fonction essaye de gérer également une rotation de caméra "rotateCameraTo", entre les points, dont le comportement est difficilement compréhensible. L'observation est qu'il ne garantie pas que la caméra
 * s'oriente dans la direction du déplacement.
 *
 * Un changement a été apporté pour que l'orientation de la caméra du sweep finale corresponde à l'angle choisie  dans le sweep (enregistrée par notre utilisateur) plutôt qu'à celle calculée par rotateCameraTo.
 *
 * @param sdk
 * @param endSweep
 * @param options
 * @returns {Promise<unknown>}
 */
export const sweepNavigateTo = async function (sdk, endSweep, options = {
    longDistance: 10,
    // rotationSpeed: 2, Deprecated
    transitionTime: 2000
}) {

    // Rotation Functions by Austin Doss / adoss@matterport.com
    const angle = (anchor, point) => Math.atan2(anchor.z - point.z, anchor.x - point.x) * 180 / Math.PI

    const rotateCameraTo = async (targetPosition) => {
        try {
            let currentPose = await sdk.Camera.getPose()
            let angleToTarget = angle(currentPose['position'], targetPosition)
            // correct for the cameras current pose rotation
            if (angleToTarget < 0) {
                angleToTarget = -(-angleToTarget - currentPose['rotation']['y'] + 90)
            } else {
                angleToTarget = angleToTarget + currentPose['rotation']['y'] - 90
            }
            // correct for turns larger that 180 degrees
            if (Math.abs(angleToTarget) > 180) {
                angleToTarget = (360 - Math.abs(angleToTarget)) % 180
            }
            /*
                        await sdk.Camera.rotate(angleToTarget, 0, {
                            speed: options.rotationSpeed
                        })
            */
            // New code -- return the rotation to use in a single SDK method
            let rotation = {
                y: angleToTarget + 180, // why does this seem like it should be x?
                x: 0
            }
            return rotation;
        } catch (err) {
            console.log(err)
        }
    }

    // Make sure that the user is actually on a sweep and return it to use as the start point
    let startSweepSID = null;
    await sdk.Sweep.current.waitUntil(async function (currentSweep) {
        startSweepSID = currentSweep.sid;
        return currentSweep.sid != '';
    });
    let sweepGraph = await sdk.Sweep.createGraph();

    const longEdges = [];
    for (const edge of sweepGraph.edges) {
        if (edge.weight > options.longDistance) {
            longEdges.push(edge);
        }
    }
    sweepGraph.removeEdge(...longEdges);

    const startSweepVertex = sweepGraph.vertex(startSweepSID);
    const endSweepVertex = sweepGraph.vertex(endSweep.sweep);

    // do standard path finding
    const aStarRunner = sdk.Graph.createAStarRunner(sweepGraph, startSweepVertex, endSweepVertex);
    const path = aStarRunner.exec().path;

    return new Promise(resolve => {
        var index = 0;

        async function goToNext(index) {
            var opt = path[index];
            if (!opt) {
                resolve(sweepGraph);
                return false;
            }

            let params = {
                transition: sdk.Sweep.Transition.FLY,
                trasitionTime: options.transitionTime
            }

            if (endSweep.sweep === opt.data.id) {
                params = endSweep
            } else if (index == 1) {
                // comportemetn de la caméra incompréhensible
                // params.rotation = await rotateCameraTo(opt.data.position);
            }

            await sdk.Sweep.moveTo(opt.data.id, params);
            goToNext(index += 1);
        };
        goToNext(0);
    });
};

export function videoPublicLink(video) {
    return Config.apiUrl + '/videos/' + video.filename;
}