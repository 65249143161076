import {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {render} from "react-dom";
import ReactModal from "react-modal";

import ReactPlayer from "react-player";
import {isMobile} from 'react-device-detect';

import {Button, Image, Modal, Spin} from "antd";
import {
    CloseOutlined,
    ShakeOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    LoadingOutlined
} from "@ant-design/icons";

import Player from "../components/Player";
import puce from "../puce.webp";

import '../Responsive.css';
import {getTour} from "../api/all";
import logo from "../logo_b.png";

/**
 * Composant necessaire pour pouvoir injecter des composant react dans une chaine composée elle même d'html
 *
 * L'injection dangerouslySetInnerHTML permet d'interpréter l'html en chaine et de l'injecter dans Document. Une fois dans Document
 * les id sont disponible à la recupération via getElementById afin de déclencher une render de Component.
 *
 * Src : https://stackoverflow.com/questions/36978767/react-using-react-component-inside-of-dangerouslysetinnerhtml
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DynamicRichRendering = (props) => {
    let html = props.content;
    html = html.replace('_next_', '<span id="content_play_next"></span>')
    html = html.replace('_back_', '<span id="content_play_back"></span>')

    useEffect(() => {
        let elem = document.getElementById('content_play_next');

        if (elem) {
            render(<Button type="success" shape="circle"
                           icon={<StepForwardOutlined/>}></Button>, document.getElementById('content_play_next'))
        }

        elem = document.getElementById('content_play_back');

        if (elem) {
            render(<Button type="success" shape="circle"
                           icon={<StepBackwardOutlined/>}></Button>, document.getElementById('content_play_back'))
        }
    }, [])

    return (<>
        <div
            dangerouslySetInnerHTML={{
                __html: html
            }}
        />
    </>)
}

/**
 *
 */
function Viewer() {

    let {id} = useParams();
    let playerRef = useRef();

    const [steps, setSteps] = useState([]);
    const [tour, setTour] = useState(null);
    const [started, setStarted] = useState(false);
    const [matterportId, setMatterportId] = useState(null);
    const [videoModalOnStart, setVideoModalOnStart] = useState(false);
    const [conclusionVideoModal, setConclusionVideoModal] = useState(false);
    const [endedModal, setEndedModal] = useState(false);
    const [screenOrientation, setScreenOrientation] = useState(null);
    const [ready, setReady] = useState(false);

    const handleOk = () => {
        setEndedModal(false);
        playerRef.current.start();
    }

    const handleCancel = () => {
        setEndedModal(false);
    };

    const closeVideoModalOnStart = () => {
        playerRef.current.start();
        setVideoModalOnStart(false);
    }

    const closeConclusionVideoModal = () => {
        setConclusionVideoModal(false);
        setEndedModal(true)
    }

    /**
     *
     * @returns {string}
     */
    const defineScreenOrientation = () => {

        let mql = window.matchMedia("(orientation: portrait)");
        let orientation = mql.matches > 0 ? 'portrait-primary' : 'landscape-primary'

        return orientation;
    }

    const onEndedVideoHandler = () => {
        closeVideoModalOnStart();
    }

    const onEndedConclusionVideoHandler = () => {
        closeConclusionVideoModal();
    }

    /**
     *
     */
    const onEnd = () => {
        if (tour.conclusion_video_url !== null) {
            setConclusionVideoModal(true);
        } else {
            setEndedModal(true)
        }
    }

    const reset = () => {
        setVideoModalOnStart(false);
        setConclusionVideoModal(false);
    }

    const init = (tour) => {
        reset();

        setSteps(JSON.parse(tour.steps));
        setTour(tour);
        setMatterportId(tour.matterport_id);

        if (tour.intro_video_url !== null) {
            setVideoModalOnStart(true);
        }
    }

    useEffect(() => {
        getTour(id).then(data => {
            init(data.tour);
        })

        setScreenOrientation(defineScreenOrientation());
        window.addEventListener("resize", function () {
            setTimeout(() => {
                let newOrientation = defineScreenOrientation();
                if (newOrientation !== screenOrientation) {
                    setScreenOrientation(newOrientation);
                }
            }, 500)
        }, false);
    }, [])

    let classes = ['guid_grid', 'viewer'];
    let modaleClasses = [];
    let brandingClasses = ['branding'];
    let endedMmodaleClasses = ['ant-modal-content-2', 'mobile'];
    let reactModaleClasses = ['react_modal', 'intro_modal'];
    if (isMobile) {
        classes.push('mobile');
        modaleClasses.push('mobile');
        endedMmodaleClasses.push('mobile');
        reactModaleClasses.push('mobile');
        brandingClasses.push('mobile');
    }

    // if (screenOrientation !== null && screenOrientation.includes('portrait')) {
    //
    //     return (<div style={{
    //         textAlign: "center", fontSize: "16px", padding: '50px 50px 0 50px'
    //     }}>
    //         <h3>Tournez votre téléphone <br/>en mode paysage</h3>
    //         <div style={{fontSize: "45px"}}><ShakeOutlined/></div>
    //     </div>);
    //
    // } else {

        if (tour === null) return 'chargement ...';

        return (<>
            {!started && <div style={{
                display: "grid",
                alignItems: "center",
                alignContent: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
                backgroundColor: "rgba(89, 89, 89, 0.5)",
                backdropFilter: "blur(20px)"
            }}>
                <div style={{
                    margin: '0 25px',
                    textAlign: "center",
                    fontSize: "22px"
                }}>
                    <div style={{
                        marginBottom: "25px",
                        fontWeight: 600,
                        color: "#fff"
                    }}>{tour['name']}</div>
                    <div style={{
                        height: "90px",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        {ready ? <Button shape="circle" style={{
                            width: "90px",
                            height: "90px",
                            backgroundColor: "transparent"
                        }} icon={<PlayCircleOutlined style={{
                            fontSize: "80px",
                            color: "#fff"
                        }}/>} onClick={() => {
                            setStarted(true);

                            if (tour.intro_video_url === null) {
                                playerRef.current.start();
                            }
                        }}/> : <Spin indicator={<LoadingOutlined style={{fontSize: 90, color: "#fff"}} spin/>}/>}

                    </div>
                </div>
            </div>}

            <div className={classes.join(' ')}>

                <Player ref={playerRef} steps={steps} tour={tour} mode={'demo'} matterportId={matterportId}
                        onRestart={() => {
                            init(tour);
                        }}
                        ready={() => {
                            setReady(true)
                        }}
                        onEnd={onEnd}/>

                <ReactModal
                    className={endedMmodaleClasses.join(' ')}
                    isOpen={endedModal && started}
                    closable={false}
                    footer={null}
                >
                    <div>
                        <div className={'modal-title'}><Image preview={false} className={'modale-title-puce'}
                                                              src={puce}/>La visite guidée est maintenant terminée
                        </div>
                        <div className={'modal-dynamic-content'}>
                            {tour.conclusion_message !== null &&
                                <DynamicRichRendering content={tour.conclusion_message}/>}
                        </div>
                        <div className={'modal-controls'}>
                            <div className={'modal-button'} onClick={handleOk}>
                                Recommencer
                            </div>
                            <div className={'modal-button'} onClick={handleCancel}>
                                Visite libre
                            </div>
                        </div>
                    </div>
                </ReactModal>

                <ReactModal
                    isOpen={videoModalOnStart && started}
                    onRequestClose={closeVideoModalOnStart}
                    className={reactModaleClasses.join(' ')}
                >
                    <Button type="dashed" className={'close'} shape="circle" style={{
                        "zIndex": 99999999
                    }}
                            icon={<CloseOutlined/>} onClick={closeVideoModalOnStart} size="large"/>
                    <ReactPlayer url={tour.intro_video_url} playing={true} pip={false} width={"100%"}
                                 height={"100%"}
                                 controls={true} onEnded={onEndedVideoHandler}/>
                </ReactModal>

                <ReactModal
                    isOpen={conclusionVideoModal && started}
                    onRequestClose={closeConclusionVideoModal}
                    className={reactModaleClasses.join(' ')}
                >
                    <Button type="dashed" className={'close'} shape="circle" style={{
                        "zIndex": 99999999
                    }}
                            icon={<CloseOutlined/>} onClick={closeConclusionVideoModal} size="large"/>
                    <ReactPlayer url={tour.conclusion_video_url} playing={true} pip={false} width={"100%"}
                                 height={"100%"}
                                 controls={true} onEnded={onEndedConclusionVideoHandler}/>
                </ReactModal>
            </div>

            {tour.show_branding && <div className={brandingClasses.join(' ')}>
                <a href={'https://www.guid3dtour.com/'} target={"_blank"}><img alt={'logo'} src={logo}/></a>
            </div>}
        </>);
    //}
}

export default Viewer;
