import LayoutPage from "../LoginLayout";
import NormalLoginForm from "./LoginForm";

let Login = () => {
  return (
      <LayoutPage title="Identification">
        <NormalLoginForm />
      </LayoutPage>
  );
}

export default Login;
