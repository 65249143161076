import {useEffect, useState} from "react";
import {Config} from "../../config";
import {Button, notification, PageHeader, Popconfirm, Space, Table} from "antd";
import {Link} from "react-router-dom";
import {CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {CSRFFetch} from "../../api/CSRFFetch";
import {deleteTour, getTour, getTours} from "../../api/all";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Tours() {

    const [dateSource, setDateSource] = useState([]);

    useEffect(() => {
        loadTours();
    }, []);

    const loadTours = () => {
        getTours().then(data => {
            setDateSource(data);
        })
    }

    /**
     *
     * @param tourId
     */
    const onDelete = (tourId) => {
        deleteTour(tourId).then(resp => {
            loadTours();
            notification['success']({
                message: 'La visite a été supprimée'
            });
        }).catch(resp => {
            notification['error']({
                message: 'Impossible de supprimer la visite'
            });
        });
    }

    /**
     *
     * @param tourId
     */
    const onClone = async (tourId) => {
        try {

            let tour = await getTour(tourId).then(resp => {
                return resp.tour;
            })

            if (tour !== null) {
                CSRFFetch(Config.apiUrl + '/api/tour', 'post', {
                    'name': '(Copie) ' + tour.name,
                    'matterport_id': tour.matterport_id,
                    'auto_play': tour.auto_play,
                    'intro_video_url': tour.intro_video_url,
                    'intro_message': tour.intro_message,
                    'conclusion_message': tour.conclusion_message,
                    'show_branding': tour.show_branding,
                    'steps': tour.steps
                }).then(resp => {
                    loadTours();
                    notification['success']({
                        message: 'La visite a été dupliquée'
                    });
                });
            }


        } catch {
            notification['error']({
                message: 'Impossible de dupliquer la visite'
            });
        }

    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: '15px',
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
        },
        {
            key: 'action',
            width: '70px',
            render: (text, record, index) => {
                return (
                    <Space>
                        <Link to={'/_admin/edit/' + record.uuid} target={"_blank"}><Button shape="circle"
                                                                                         icon={<EditOutlined/>}/></Link>
                        <Link to={'/viewer/' + record.uuid} target={"_blank"}><Button shape="circle"
                                                                                    icon={<EyeOutlined/>}/></Link>
                        <Popconfirm placement="topRight" title={"Êtes vous sur de vouloir dupliquer cette visite ?"}
                                    onConfirm={() => {
                                        onClone(record.uuid)
                                    }} okText="Oui" cancelText="Non">
                            <Button shape="circle" icon={<CopyOutlined/>}/>
                        </Popconfirm>
                        <Popconfirm placement="topRight" title={"Êtes vous sur de vouloir supprimer cette visite ?"}
                                    onConfirm={() => {
                                        onDelete(record.uuid)
                                    }} okText="Oui" cancelText="Non">
                            <Button shape="circle" icon={<DeleteOutlined/>} type="primary" danger/>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ];

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Visites guidées"
            />
            <Table dataSource={dateSource} columns={columns}/>
        </>
    )
}


export default Tours;

