import {Config} from "../config";
import Cookies from "js-cookie";

export const CSRFFetch = (url, method, body, file) => {
    return fetch(Config.apiUrl + '/sanctum/csrf-cookie', {
        method: 'GET',
        'credentials': 'include',
    }).then(resp => {
        let init = {
            method: method,
            'credentials': 'include',
            headers: {
                'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json'
            }
        }

        if (body !== null) {
            init.body = JSON.stringify(body);
            init.headers['Content-Type'] = 'application/json';
        } else if (file) {
            const formData = new FormData();
            formData.append('file', file);
            init.body = formData;
        }



        return fetch(url, init).then(resp => {

            if (resp.status !== 200) {
                throw Error();
            }

            return resp.json();
        });
    });
}
